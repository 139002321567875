<template>
  <div>
    <v-progress-linear indeterminate v-if="userDefinedLoading || widget.loader"></v-progress-linear>
    <v-card v-else-if="!userDefinedLoading" flat height="100%" :class="$vuetify.theme.dark ? 'dark-theme-chart' : ''">
      <highcharts :options='widget.dataformat === 1 ? widget.barChartOptions : widget.pieChartOptions' v-if='[1, 2].includes(widget.dataformat)' ></highcharts>
    </v-card>
    <v-data-table mobile-breakpoint="0" v-if='widget.dataformat === 3' :headers="widget.headers" class="userdefined-table rounded-xl" :items="widget.listData" :must-sort="true" dense height='310px' :server-items-length="widget.total"
    :footer-props="paginationList" :options.sync="paginationMap" @update:page="updatePagination('page', $event)" @update:items-per-page="updatePagination('items', $event)" disable-sort>
      <template #body="{ items, headers }">
      <tbody>
      <tr v-for="(item, i) in items" :key="i">
      <td v-for="(header, index) in headers" :key="index">
      <div v-if="index === 0" style="cursor: pointer; color: #0288D1" @click.stop="$router.push(`${item.url}/${item._id}`)">{{item.data[header.value.split('.')[1]] ? item.data[header.value.split('.')[1]] : ''}}</div>
      <div v-else>
        <span :title="item.data[header.value.split('.')[1]]" class="d-inline-block text-truncate" :style="`max-width: 180px; cursor: text`">{{item.data[header.value.split('.')[1]] ? item.data[header.value.split('.')[1]] : ''}}</span>
      </div>
      </td>
      </tr>
      </tbody>
      </template>
      <!-- <template v-slot:[`item.data.name`]="{ item }">
         <div style="cursor: pointer; color: #0288D1" @click.stop="$router.push(`${item.url}/${item._id}`)">{{ item.data.name }}</div>
      </template> -->
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: ['userDefinedLoading', 'widget', 'pagination'],
  data () {
    return {
      paginationMap: this.pagination
    }
  },
  computed: {
    paginationList () {
      return {
        itemsPerPageOptions: [5, 10, 25, 50, 100, 250],
        itemsPerPageText: this.$t('resultsPerPage')
      }
    }
  },
  methods: {
    updatePagination (item, value) {
      const model = this.$formatter.cloneVariable(this.paginationMap)
      switch (item) {
        case 'page':
          model.page = value
          break
        case 'items':
          model.limit = value
          break
          // case 'sortby':
          //   model.sortBy = value && value.length ? value[0].split('.')[1] : ''
          //   break;
          // case 'desc':
          //   model.descending = value && value.length ? value[0] : false
          //   break;
        default:
          break
      }
      model.limit = model.itemsPerPage
      model.skip = (model.page - 1) * model.limit
      model.dashboardId = this.widget.databaseindex
      model.sortBy = ''
      model.descending = false

      this.$root.$emit('tableWidgetPagination', { model, widget: this.widget })
    }
  }
}
</script>

<style>
.userdefined-table .v-data-footer {
  margin-right: 0px !important;
  padding: 0px;
  justify-content: center;
}
.userdefined-table .v-data-footer__select {
  margin-right: 0px;
  margin-left: 0px;
}
.userdefined-table .v-data-footer__select .v-select {
  margin: 13px 0 13px 14px;
}
.userdefined-table .v-data-footer__pagination {
  margin: 0px 10px 0px 10px;
}
.userdefined-table .v-data-footer__icons-after .v-btn:first-child {
  margin-left: 0px;
}
.userdefined-table .v-data-footer__icons-before .v-btn:first-child {
  margin-right: 0px;
}
</style>
